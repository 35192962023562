input {
  max-width: 30em;

  &form-control:invalid {
    background-color: pink;
  }
}

.member-list {
  margin-top: 1em;

  span {
    text-wrap: nowrap;
  }

  .member-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0em 1em;
    margin-bottom: 0.5em;

    &:nth-child(even) {
      background-color: rgba(128, 128, 128, 0.15);
    }

    &.expired .member-expires {
      color: darkred;
    }
  }

  .member-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 20em;

    .member-name {
      flex-grow: 1;
      font-weight: bold;
    }

    .member-org {
      flex-grow: 0;
      width: 7em;
    }
  }

  .member-status {
    display: flex;
    flex-grow: 1;
    opacity: 0.7;
    width: 15em;

    .member-level {
      flex-grow: 1;
      max-width: 10em;
    }
    .member-expires {
      flex-grow: 0;
      width: 10em;
    }
  }
}

.meta-section {
  opacity: 0.7;
  font-size: 0.8rem;

  h2 {
    padding: 0;
    font-weight: bold;
    font-size: 1em;
  }
}

.meta-grid {
  display: grid;
  // two columns, with min width on the first column and max width on the second
  grid-template-columns: minmax(10em, 1fr) max-content;
}
.meta-row {
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
